.projectsList {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0;
  margin: 0;
}

.project-card {
  width: 35vw;
  display: flex;
  align-items: flex-end;
  border-radius: 1em;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  will-change: transform;
}

.project-card:hover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 20%, #0e1111 100%);
  z-index: 1;
}

.project-card img {
  width: 100%;
}

.project-card:hover {
  transform: translateY(-10px);
}

.project-card:hover .content-slate {
  visibility: visible;
  opacity: 1;
}

.content-slate {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 1em;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.content-slate a {
  font-size: x-large;
  font-weight: 700;
  border-bottom: 1px solid white;
  width: fit-content;
  color: white;
  transition: all 0.1s ease-in-out;
}
.content-slate a:hover {
  transform: translateY(-5px);
}

.content-slate p {
  font-size: 14px;
  color: white;
  line-height: 1.5em;
}

.project-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  z-index: 1;
}

.arrow svg {
  fill: white !important;
}

.project-stack div {
  font-size: 13px;
  text-align: center;
  border-radius: 0.5em;
  padding: 0.2rem 0.4rem;
  /* background-color: #11263f; */
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: black;
  font-weight: 500;
}

@media (max-width: 1000px) {
  .content-slate p {
    font-size: x-small;
  }
}

@media (max-width: 850px) {
  .project-card {
    min-width: 25em;
  }
  .content-slate a {
    font-size: large;
  }
  .project-stack div {
    font-size: xx-small;
  }
}

@media (max-width: 650px) {
  .content-slate a {
    font-size: medium;
  }

  .project-stack {
    gap: 5px 5px;
  }

  .project-stack div {
    font-size: 8px;
    padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  }
}

@media (max-width: 400px) {
  .project-card {
    min-width: 20em;
  }
}
