* {
  font-family: montserrat;
  color: black;
}

@font-face {
  font-family: "montserrat";
  src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf") format(truetype);
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: #f7f3e3;
  background-size: 400% 400%;
  padding: 0;
  animation: gradient 5s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a:link,
a:visited {
  text-decoration: none;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
  font-size: 30px;
  border-bottom: 1px solid black;
}

.container {
  font-family: sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.navBehavior {
  position: sticky;
  top: 0px;
  z-index: 50;
}
