section {
  font-family: sans-serif;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.aboutContainer {
  display: flex;
  gap: 8rem;
  margin: 8rem 0 16rem 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
}

.bioContainer {
  margin-top: 0;
  max-width: 30em;
}

.coverpicture {
  width: 27.5rem;
  height: 27.5rem;
  border-radius: 9999px;
  outline: 1px solid black;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  transition: all 0.25s ease-in-out;
  object-fit: cover;
}

.coverpicture:hover {
  outline: 1.5px solid #f7f3e3;
  transform: scale(1.05);
}

.hello {
  border-bottom: 1px solid black;
  font-size: 1.2em;
  display: inline-block;
  position: relative;
}

.aboutme {
  display: block;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0;
}

.shortbio {
  display: block;
  font-size: 1em;
  margin-top: 1em;
  line-height: 1.5rem;
}

.shortbio a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the same color as surrounding text */
}

.shortbio a:hover {
  text-decoration: underline; /* Add underline on hover */
}

@media (max-width: 900px) {
  .aboutContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin-bottom: 0;
  }

  .aboutContainer img {
    width: 50vw;
    height: 50vw;
    margin: 0;
    padding: 0;
  }

  .bioContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .aboutme {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .shortbio {
    margin: 1.5rem;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .aboutContainer img {
    width: 18rem;
    height: 18rem;
  }
}
