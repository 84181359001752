.contactGrid {
  display: flex;
  align-items: flex-end;
  gap: 5em;
  width: 45vw;
  justify-content: space-around;
}

.contact-card {
  display: flex;
  flex-direction: column;
  width: 30em;
  border: 0.1px solid gray;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  z-index: 1;
}

.prompt {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 1em;
  font-weight: 500;
}

.prompt textarea {
  padding: 10px 0 10px 10px;
  min-height: 6em;
  max-height: 30em;
}

.prompt input {
  padding-left: 10px;
}

.field {
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 0.5em;
  margin-top: 1em;
  min-height: 2em;
  font-size: 15px;
  text-align: left;
  transition: all 0.15s ease-in-out;
  resize: vertical;
}

.field:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.send {
  margin: 1em auto;
  width: 5rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  border: 1px solid gray;
  padding: 0.7em;
  text-align: center;
  transition: all 0.25s ease-in-out;
}

.send:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.information-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.contact-type {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  border: 0.5px solid gray;
  border-radius: 1em;

  padding: 1em;
  gap: 1em;
  font-size: 15px;
  font-weight: 500;
  align-items: center;
  transition: all 0.25s ease-in-out;
  z-index: 1;
}

.contact-type:hover {
  transform: scale(1.025);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 2rem;
}

.information-socials {
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 1em 0 1em 0;
  width: 100%;
}

.footer-socials {
  display: flex;
  font-size: 2rem;
  gap: 2em;
  transition: all 0.25s ease-in-out;
}

.footer-socials:hover {
  transform: scale(1.1);
}

.footer {
  margin-top: 12em;
  border-top: 1px solid black;
  padding: 2rem 0 2rem 0;
  left: 0;

  width: 100%;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  text-align: left;
  text-indent: 10rem;
  z-index: 1;
}

.Toastify__toast {
  border: 1px solid white !important;
  border-radius: 1.3em !important;
  background: #f7f3e3 !important;
  font-weight: 500;
}

.toastProgress {
  background: rgb(0, 0, 0) !important;
}

@media (max-width: 1300px) {
  .contactGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 850px) {
  .contactGrid {
    margin: 0;
    padding: 0;
    width: 50vw;
  }

  .contact-card {
    width: 80vw;
    height: auto;
  }

  .information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto 3em auto;
    padding: 0;
  }
  .footer {
    text-indent: 32px;
  }
}
