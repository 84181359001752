.experience__list {
  width: 50vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  gap: 1em;
}

.experience__list:first-of-type article {
  margin-top: 0;
}

.experience__list:last-of-type article {
  margin-bottom: 0;
}

.experience__card {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  font-size: 17px;
  gap: 5em;
  margin: 50px 0 50px 0;
  padding: 50px 0 50px 0;
  align-items: center;
  transition: all 0.25s ease-in-out;
  min-width: fit-content;
}

.experience__card img {
  height: 140px;
}

.experience__card div {
  max-width: 50%;
  display: flex-grow;
}

.experience__card p {
  margin: 0;
}

.experience__card:hover {
  transform: translateY(-10px);
}

.experience__employer {
  width: max-content;
  border-bottom: 1px solid black;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  margin: 0;
}

.experience__time {
  font-size: 16px;
  padding-top: 10px;
}
.experience__description {
  padding-top: 10px;
  display: inline-block;
  font-size: 1em;
}

@media (max-width: 1300px) {
  .experience__employer {
    font-size: 14px;
  }
  .experience__time {
    font-size: 12px;
  }
  .experience__card {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  .experience__card {
    border: 0;
  }
}

@media (max-width: 450px) {
  .experience__card {
    flex-direction: column;
    text-align: center;
    gap: 1em;
    align-items: center;
  }
  .experience__card div {
    width: 100%;
    display: contents;
  }

  .experience__employer {
    padding: 0;
    margin: 0;
    text-indent: 0;
  }

  .experience__time {
    padding: 0;
  }

  .experience__description {
    width: 200px;
    padding: 0;
  }
}
