.nav {
  padding-bottom: 0.75rem;
  border-bottom: 0.5px solid black;
  background-color: transparent;
  font-family: sans-serif;
  backdrop-filter: blur(5px);
  top: 0px;
  margin: 0;
}

.nav__items {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.nav__logo {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-left: 4rem;
  white-space: nowrap;
}

.nav__menu {
  display: flex;
  gap: 0.5rem;
}

.nav__sections {
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  gap: 2.5rem;
}

.nav__icons {
  transition: all 0.25s ease-in-out;
}
.nav__icons:hover {
  transform: scale(1.1);
  transform: translateY(-2px);
}

.nav__socials {
  display: flex;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 2.5rem;
  gap: 1.25rem;
  border-left: 1px solid black;
}

.nav__burger-menu {
  display: none;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 1000px) {
  .nav__sections {
    gap: 15px;
  }
  .nav__socials {
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 850px) {
  .nav__logo {
    margin-left: 32px;
    align-items: center;
    position: absolute;
    bottom: 20px;
  }

  .nav__burger-menu {
    display: flex;
    position: relative;
    padding: 0;
    bottom: 5px;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    margin-right: 16px;
    font-size: 32px;
  }
  .nav__menu {
    display: none;
    flex-direction: column;
    border-bottom: 1px solid white;
    padding: 1rem 1rem 1rem 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .nav__socials {
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .nav__sections {
    padding: 1rem 0 1rem 0;
    justify-content: center;
    width: 100vw;
  }

  .nav__menu.open {
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
  }

  .nav__menu.open .nav__socials,
  .nav__menu.open .nav__socials a,
  .nav__menu.open .nav__icons {
    color: white !important; /* Ensure white color */
  }

  .nav__menu span {
    display: none;
  }
}

@media (max-width: 450px) {
  .nav__sections {
    gap: 10px;
  }

  .nav__sections a {
    font-size: 14px;
  }
}
