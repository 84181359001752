.filters {
  display: flex;
  justify-content: center;

  gap: 1em;
  margin-bottom: 1em;
}

.filter-btn {
  width: 100px;
  height: 50px;
  background-color: transparent;
  font-size: medium;
  font-weight: 500;
  border: 0;
  border-radius: 0.5em;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.filter-btn.active {
  transform: translateY(-10px);
  background-color: rgba(0, 0, 0, 0.05);
}

.filter-btn:hover {
  transform: scale(1.05);
}

.skills {
  width: 45vw;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  justify-items: center;
  row-gap: 0.5em;
  border-radius: 1em;
}

.techs {
  width: 8rem;
  height: 7rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  font-size: 15px;
  font-weight: 500;
  transition: all 0.25s ease-in-out;
  gap: 0.5rem;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.5em;
}

.techs:hover,
.techs:active {
  cursor: pointer;
  transform: scale(1.05);
}

.logo {
  height: 3.5rem;
}

@media (hover: none) {
  .techs:hover,
  .techs:active {
    background-color: initial;
    cursor: default;
    box-shadow: none;
    transform: none;
  }
}

@media (max-width: 1500px) {
  .techs {
    width: 6rem;
    height: 6rem;
  }
  .logo {
    height: 2.5rem;
  }
}

@media (max-width: 1100px) {
  .skills {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 55vw;
  }
}

@media (max-width: 900px) {
  .skills {
    border: 0;
    gap: 0.2em;
    width: 60vw;
  }

  .filter-btn {
    width: 80px;
    height: 40px;
    font-size: 13px;
  }

  .techs {
    width: 5rem;
    height: 5rem;
    font-size: 13px;
  }
  .logo {
    height: 2rem;
  }
}

@media (max-width: 750px) {
  .filter-btn {
    width: 70px;
    height: 35px;
    font-size: 12px;
  }
  .techs {
    width: 5rem;
    height: 5rem;
  }
}

@media (max-width: 600px) {
  .skills {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 450px) {
  .filters {
    gap: 5px;
  }
  .techs {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 11px;
  }
  .logo {
    height: 1.8rem;
  }
}

@media (max-width: 400px) {
  .skills {
    width: 70vw;
  }
  .filters {
    gap: 0;
    width: 100vw;
  }
  .filter-btn {
    width: 7em;
    height: 2.2em;
    font-size: 10.5px;
    text-align: center;
  }
}
